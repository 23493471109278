import React from 'react'
import HeaderBack from "./HeaderBack";
import HeaderLogo from "./HeaderLogo";
import HeaderTextProgress from "./HeaderTextProgress";
import {stepsCount} from "../QuizRoot";
import HeaderProgress from "./HeeaderProgress";
import {useHistory} from "react-router-dom";

interface Props {
    step: number,
    setStep: (val: number) => void
}

export default function Header(props: Props) {
    const {step, setStep} = props;
    const history = useHistory();

    const onClick = () => {
        if (step > 1) {
            setStep(step - 1);
        } else {
            history.goBack();
        }
    }

    return (
        <div className={"header"}>
            <div className={"container"}>
                <div className={"row"}>
                    <HeaderBack step={step} onClick={onClick}/>
                    <HeaderLogo/>
                    <HeaderTextProgress current={step} all={stepsCount}/>
                </div>
            </div>
            <HeaderProgress current={step} all={stepsCount}/>
        </div>
    )
}

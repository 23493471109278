import React from "react";
import {stepArrow, StepProps} from "../QuizRoot";

export const step12Index = 13


export default function Step12(props: StepProps) {
    const onClick = (index: number) => {
        props.addAnswer(step12Index, index);
        props.nextStep();
    }

    return (
        <div className={"form-content"}>
            <p className={"text-center mb-4"}>This information helps us to find the best content for you.<br/>It's private and will not be shared.</p>
            {Step12Variants.map((variant: string, index: number) => {
                return (
                    <button key={"step-12-" + index}
                            className={"btn-quiz " + (props.answer[step12Index] === index ? "active" : "")}
                            onClick={() => onClick(index)}>
                        <span>{variant}</span> {stepArrow}
                    </button>
                )
            })}
        </div>
    )
}

export const Step12Variants = [
    "Under 26",
    "26-35",
    "36-45",
    "46-55",
    "56+",
]

import React, {useState} from "react";
import {stepArrow, StepProps} from "../QuizRoot";
import NextBtn from "../body/NextBtn";

export const step9Index = 9


export default function Step9(props: StepProps) {
    const [selectedVals, setSelectedVals] = useState<number[]>(props.answer[step9Index]?props.answer[step9Index]:[]);
    const onClick = (index: number) => {
        if (selectedVals.indexOf(index) === -1) {
            setSelectedVals([...selectedVals, index]);
        } else {
            setSelectedVals([...selectedVals.filter(i => selectedVals[i] !== index)]);
        }
    }

    const onNext = () => {
        props.addAnswer(step9Index, selectedVals)
        props.nextStep();
    }

    return (
        <div className={"form-content"}>
            <p className={"mb-4 text-center"}>Select all that apply</p>
            {variants.map((variant: string, index: number) => {
                return (
                    <button key={"step-9-" + index}
                            className={"btn-quiz " + (selectedVals.indexOf(index) > -1? "active-multiple" : "")}
                            onClick={() => onClick(index)}>
                        <span>{variant}</span> {stepArrow}
                    </button>
                )
            })}

            <NextBtn onClick={onNext} disabled={selectedVals.length === 0}/>
        </div>
    )
}

const variants = [
    "Calming Bible stories",
    "Inspiring Bible verses ",
    "Powerful prayers",
    "Healing prayers",
    "Empowering sermons",
    "Meditating prayers",
    "Praise and worship songs",
    "Stories from the community",
]

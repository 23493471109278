import React, {Fragment} from "react";
import BgOverlay from "./BgOverlay";
import IntroImg from "../../../assets/images/Blessify_hero_block.png"
import FiveStarImg from "../../../assets/images/fivestar.png"

interface Props {
    children: any,
    step: number,
    setStep: (val: number) => void,

}

const titles = [
    "Welcome to Blessify",
    "How have you been feeling lately?",
    "What brings you here today?",
    "Congratulations on taking the first step towards a better you.",
    "What can we help you with today?",
    "That's OK.",
    "At this moment, how motivated are you to work on your problems?",
    "What would motivate you most to keep up with your goals?",

    "What type of content would you prefer?",
    "",
    "How familiar are you with the Bible?",
    "Great!",
    "How old are you?",
    "",
    "What is your gender?",
    "Trusted by over 5 million member just like you"
];


export default function QuizContainer(props: Props) {
    const titleIndex = props.step - 1;

    return (
        <div className={"quiz-container"}>
            <BgOverlay/>
            {/*{props.step === 1 && <div className={"intro-container"}>*/}
            {/*    <div className={"text-center"}><img src={IntroImg} className={"intro-image"}/></div>*/}
            {/*    <div className={"text-center"}><img src={FiveStarImg} width={120}/></div>*/}
            {/*    <div className={"text-center"}>4.8 stars on 1000+ reviews</div>*/}
            {/*</div>}*/}
            <div className={"container"}>

                {titleIndex < titles.length && !!titles[titleIndex] && <h1>{titles[titleIndex]}</h1>}
                {props.children}
            </div>
        </div>
    )
}

import React from "react";
import {stepArrow, StepProps} from "../QuizRoot";
export const step8Index = 8

export default function Step8(props: StepProps) {
    const onClick = (index: number) => {
        props.addAnswer(step8Index, index);
        props.nextStep();
    }

    return (
        <div className={"form-content"}>
            {variants.map((variant: string, index: number) => {
                return (
                    <button key={"step-8-" + index}
                            className={"btn-quiz " + (props.answer[step8Index] === index ? "active" : "")}
                            onClick={() => onClick(index)}>
                        <span>{variant}</span> {stepArrow}
                    </button>
                )
            })}
        </div>
    )
}

const variants = [
    "Content based on your mood",
    "Daily prayer reminders",
    "Progress tracking",
    "Support from the community",
]

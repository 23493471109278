import React from "react";
import {stepArrow, StepProps} from "../QuizRoot";

export const step14Index = 15


export default function Step14(props: StepProps) {
    const onClick = (index: number) => {
        props.addAnswer(step14Index, index);
        props.nextStep();
    }

    return (
        <div className={"form-content"}>
            <p className={"text-center mb-4"}>This information helps us to find the best content for you.<br/>It's private and will not be shared.</p>
            {Step12Variants.map((variant: string, index: number) => {
                return (
                    <button key={"step-14-" + index}
                            className={"btn-quiz " + (props.answer[step14Index] === index ? "active" : "")}
                            onClick={() => onClick(index)}>
                        <span>{variant}</span> {stepArrow}
                    </button>
                )
            })}
        </div>
    )
}

export const Step12Variants = [
    "Male",
    "Female",
    "Other",
]

import React from "react";


export default function
    HeaderLogo() {
    const goToPage = () => {
        window.location.href = 'https://blessify.com/';
    }
    return (
        <div className={"logo"} style={{cursor: 'pointer'}} onClick={goToPage}>
            <img src={"https://blessify.com/wp-content/uploads/2021/07/blessify_logo_1.svg"} height={30}/>
        </div>
    )
}

const logo = <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M50 24.9713C50 38.7486 38.806 49.9426 25.0287 49.9426C24.5695 49.9426 24.1102 49.9426 23.651 49.8852C23.5936 49.8852 23.5936 49.8852 23.5362 49.8852C22.9621 49.8278 22.3881 49.8278 21.8714 49.713C12.5144 48.5649 4.70723 42.1355 1.54994 33.5821C1.37773 33.1228 1.20551 32.6636 1.0907 32.2044C1.0333 31.9173 0.918485 31.6877 0.861079 31.4007C0.287026 29.3915 0 27.2101 0 24.9713C0 11.194 11.194 0 24.9713 0C38.806 0 50 11.194 50 24.9713Z"
        fill="#26C6DA"/>
    <path
        d="M38.5191 39.208C37.1413 39.6673 24.627 36.6248 24.2826 37.8303C24.0529 38.5191 24.1103 44.4319 23.6511 49.9428C23.5937 49.9428 23.5937 49.9428 23.5363 49.9428C22.9622 49.8854 22.3882 49.8854 21.8715 49.7706C12.5145 48.6225 4.70735 42.1931 1.55006 33.6397C1.37785 33.1805 1.20563 32.7212 1.09082 32.262C4.01849 30.138 9.81642 25.4881 11.4812 20.4939C11.5386 20.2069 10.046 18.2551 9.75902 16.5903C8.95534 11.7109 14.1218 7.46289 14.1218 7.46289C14.1218 7.46289 15.3847 11.1942 16.8773 13.6053C17.5087 14.696 18.255 15.4422 18.8865 15.6144C20.0346 15.8441 22.6178 15.7867 23.9955 16.1885C24.1677 16.2459 24.34 16.3033 24.5122 16.4181C27.2102 17.8532 29.3342 20.2069 30.0805 22.5605C31.1138 25.9474 41.0449 29.1047 42.2504 30.3676C43.3985 31.5731 39.8968 38.7488 38.5191 39.208Z"
        fill="white"/>
    <path
        d="M21.4123 6.7168C21.4123 6.7168 16.5328 10.1611 16.8773 14.9832C17.2217 19.8052 19.6901 18.3701 19.8624 18.3701C20.0346 18.3701 24.2252 17.1646 24.2252 17.1646L21.4123 6.7168Z"
        fill="white"/>
</svg>

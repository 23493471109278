import React, {Fragment, useEffect, useState} from "react";
import {step1Index, Step1Questions} from "../questions/Step1";
import {step3Index, Step3Questions} from "../questions/Step3";
import {step5Index, Step5Questions} from "../questions/Step5";
import WinnersImg from "../../../assets/images/result/winners2021.png"

interface Props {
    nextStep: () => void,
    answer: any,
}

export default function QuizProgress(props: Props) {
    const [percent, setPercent] = useState(1);
    const [email, setEmail] = useState('');

    const goToResult = () => {

        const step1 = Step1Questions[props.answer[step1Index]].type;
        const step3 = Step3Questions[props.answer[step3Index]].type;
        const step5 = Step5Questions[props.answer[step5Index]].type;

        let _result: any = {'calm': 0, 'inspire': 0, 'heal': 0};
        _result[step1]++;
        _result[step3]++;
        _result[step5]++;


        if (_result['calm'] >= 2) {
            window.location.href = ' https://blessify.com/127550-2';
        } else if (_result['inspire'] >= 2) {
            window.location.href = ' https://blessify.com/128550-2';
        } else if (_result['heal'] >= 2) {
            window.location.href = ' https://blessify.com/126550-2';
        } else {
            window.location.href = ' https://blessify.com/128550-2';
        }
    }

    const addPercent = () => {
        const nextPercent = percent + (Math.floor(Math.random() * 10) + 10);
        if (nextPercent > 100) {
            setPercent(100);
            // props.nextStep();
        } else {
            setPercent(nextPercent);
        }
    };

    useEffect(() => {
        setTimeout(addPercent, 300);
    }, [percent]);

    const saveData = (data: any) => {
        fetch('https://quiz.blessify.com/save/praytify',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }
        ).then(() => {

        }).catch(e => {
        });
    }
    useEffect(() => {
        saveData(props.answer)
    }, []);


    const saveEmail = () => {
        if (!email) {
            return '';
        }
        const url = 'https://praytify.767.lt/app/quiz/cart/add';
        fetch(url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({email})
            }
        ).then(() => {
            goToResult();
        }).catch(e => {
            goToResult();
        });
    }

    const sendGa = () => {
        try {
            // @ts-ignore
            window.dataLayer.push({
                'event': 'quizCustomClick',
                'category': 'View',
                'action': 'View',
                'label': 'SubmitEmailWindow'
            });
        } catch (e) {
            console.log('gtm err');
        }
    }
    useEffect(() => {
        sendGa();
    }, []);


    return (
        <Fragment>
            {percent < 100 ? <div className={"calculating"}>
                <div className={"square"}>
                    {percent}%
                </div>
                <div className={"text"}>
                    Calculating results...
                </div>
            </div> : <div className={"form-content"}>
                <div>
                    <img src={WinnersImg} style={{width: '100%', marginBottom: 20}}/>
                </div>
                <p style={{fontWeight: 500, fontSize: 16, marginBottom: 30, textAlign: 'center'}}>
                    Rediscover your inner peace with the Blessify’s guided content. Claim Your Unlimited access to high
                    quality content personally picked to match your current emotional state.
                </p>
                <p>
                    <input value={email} onChange={e => setEmail(e.target.value)} className={"form-control"}
                           placeholder={"Please enter your email"}/>
                </p>
                <button onClick={saveEmail} className={"btn-next"}>Continue</button>

                <p style={{fontSize: 14, marginTop: 30, textAlign: 'center', color: "#999"}}>
                    By providing your email, you consent to receive Blessify newsletters. We respect your privacy and
                    will send only personally tailored emails. You can unsubscribe any time.
                </p>

            </div>}

        </Fragment>
    )
}

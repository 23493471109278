import React, {Fragment} from "react";

interface Props {
    current: number,
    all: number,
}

export default function HeaderTextProgress(props: Props) {

    return (
        <div className={"text-progress"}>
            {props.current > props.all ? <Fragment/> : <Fragment><span>{props.current}</span> of {props.all}</Fragment>}
        </div>
    )
}

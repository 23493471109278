import React from "react";

interface Props {
    disabled?: boolean;
    onClick: () => void
}

export default function NextBtn(props: Props) {
    const onClick = () => {
        if (props.disabled) return;

        props.onClick();
    }

    return (
        <button disabled={props.disabled} onClick={onClick} className={"btn-next"}>Next</button>
    )
}

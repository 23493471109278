import React from "react";
import {stepArrow, StepProps} from "../QuizRoot";

export const Step1Questions = [
    {
        title: 'I want to overcome depression',
        type: 'heal',
        response: '84% of people who used Blessify 5x a week overcame depression and saw an improvement in their mental health.'
    },
    {
        title: 'I\'m searching for inner peace',
        type: 'calm',
        response: '84% of people who used Blessify 5x a week found inner peace and saw an improvement in their mental health.'
    },
    {
        title: 'I\'m asking for forgiveness',
        type: 'calm',
        response: '84% of people who used Blessify 5x a week took action and committed to not repeating their mistakes.'
    },
    {
        title: 'I\'m seeking the strength to overcome difficult events',
        type: 'heal',
        response: '84% of people who used Blessify 5x a week found the strength to overcome difficult events.'
    },

    {
        title: 'I\'m praying for good luck',
        type: 'inspire',
        response: '84% of people who used Blessify 5x a week increased their good luck prayers and saw an improvement in their mental health and life.'
    },
    {
        title: 'I want to improve the quality of my sleep',
        type: 'calm',
        response: '84% of people who used Blessify 5x a week improved their sleep quality and saw an improvement in their mental health.'
    },
    {
        title: 'I\'m trying to heal after the loss of loved one',
        type: 'heal',
        response: '84% of people who used Blessify 5x a week saw an improvement in their mental health and healed their soul after the loss of loved one.'
    },

    {
        title: 'I\'m seeking self-improvement',
        type: 'inspire',
        response: '84% of people who used Blessify 5x a week saw an significant change in their commitment to self-improvement.'
    },
    {
        title: 'I\'m trying to heal after an addiction',
        type: 'heal',
        response: '84% of people who used Blessify 5x a week found support for healing after an addiction and improved their mental health.'
    },
    {
        title: 'I\'m trying to find hope and inspiration',
        type: 'inspire',
        response: '84% of people who used Blessify 5x a week found hope and inspiration in their life.'
    },
];
export const step1Index = 3;

export default function Step1(props: StepProps) {
    const onClick = (index: number) => {
        props.addAnswer(step1Index, index);
        props.nextStep();
    }

    return (
        <div className={"form-content lg"}>
            {/*<p className={"text-center mb-6"} style={{lineHeight: '160%'}}>*/}
            {/*    We’re excited that you have made a commitment to receive Jesus Christ as your Savior and serve Him as*/}
            {/*    your Lord. This is the most important day of your life!*/}
            {/*    <br/>*/}
            {/*    Enjoy Blessify’s unlimited guided content and prayers to grow your inner strength and bond with Jesus.*/}
            {/*    Please take a moment to complete the quiz below, and we’ll*/}
            {/*    create a personalized pray assistant specially for you.*/}
            {/*</p>*/}
            {Step1Questions.map((variant: any, index: number) => {
                return (
                    <button key={"step-1-" + index}
                            className={"btn-quiz " + (props.answer[step1Index] === index ? "active" : "")}
                            onClick={() => onClick(index)}>
                        <span>{variant.title}</span> {stepArrow}
                    </button>
                )
            })}
        </div>
    )
}

import React from "react";

import BottomLeftImg from "../../../assets/images/bg/bgBottomLeft.png"
import TopRightImg from "../../../assets/images/bg/bgTopRightSummary.png"

export default function Slide1BgOverlay() {
    return (
        <div className={"bg-overlay-slide1"}>
            <img src={BottomLeftImg} className={"bottom-left"} alt={""}/>
            <img src={TopRightImg} className={"top-right"} alt={""}/>
        </div>
    )
}


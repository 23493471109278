import React from "react";
import {stepArrow, StepProps} from "../QuizRoot";

export const Step3Questions = [
    {title: '🤗 Happy', type: 'inspire', response: 'I was feeling happy but it felt temporary and fragile'},
    {title: '😥 Sad', type: 'heal', response: 'I\'ve felt sad and empty'},
    {title: '😰 Anxious', type: 'calm', response: 'I\'ve felt anxious and nervous'},
    {title: '😪 Can\'t Sleep', type: 'calm', response: 'I\'ve not slept well'},

    {title: '😬 Stressed', type: 'calm', response: 'I\'ve felt stressed and overwhelmed'},
    {title: '🥺 Can\'t Focus', type: 'calm', response: 'I haven\'t been able to focus'},
    {title: '🤕 Hurt', type: 'heal', response: 'I\'ve felt hurt and rejected'},

    {title: '😱 Lost', type: 'inspire', response: 'I\'ve felt lost and empty'},
];

export const step3Index = 2

export default function Step3(props: StepProps) {
    const onClick = (index: number) => {
        props.addAnswer(step3Index, index);
        props.nextStep();

        sendGa(Step3Questions[index].title);
    }


    const sendGa = (title: string) => {
        try {
            // @ts-ignore
            window.dataLayer.push({
                'event': 'quizCustomClick',
                'category': 'Click',
                'action': '1st Answer',
                'label': title
            });
        } catch (e) {
            console.log('gtm err');
        }
    }

    return (
        <div className={"form-content lg"}>
            <p className={"text-center mb-6"} style={{lineHeight: '160%'}}>
                It's important to take a moment and be aware of how you feel.<br/>
                This will help you get the most out of your prayers.
            </p>
            {Step3Questions.map((variant: any, index: number) => {
                return (
                    <button key={"step-3-" + index}
                            className={"btn-quiz " + (props.answer[step3Index] === index ? "active" : "")}
                            onClick={() => onClick(index)}>
                        <span>{variant.title}</span> {stepArrow}
                    </button>
                )
            })}
        </div>
    )
}


import React from "react";
import Slide1BgOverlay from "./Slide1BgOverlay";
import SummaryHeader from "./SummaryHeader";
import LbsGraph from "../../../assets/images/summary/lbs-graph.png"
import LbsGraphRevert from "../../../assets/images/summary/lbs-graph-revert.png"
import {SummaryProps} from "../QuizSummary";
import moment from 'moment'

const days = [
    "MON",
    "TUE",
    "WED",
    "THU",
    "FRI",
    "SAT",
    "SUN"
]

export default function Slide1(props: SummaryProps) {
    const genderKey = props.answer.gender === 'boy' ? 'male' : 'female';
    const answer5 = props.answer[5];
    let baseWeight = 0;

    const avgWeight = props.answer[2].other[genderKey].avg === '-' ? props.answer[4].weight : props.answer[2].other[genderKey].avg;

    const coefW = answer5 === 0 ? 1.02 : (answer5 === 1 ? 0.98 : 0.95)
    if (props.answer[4].weight) {
        baseWeight = parseInt(props.answer[4].weight, 10);
    } else {
        baseWeight = Math.round(parseInt(avgWeight, 10) / coefW);
    }
    const resultWeight = Math.floor(baseWeight * coefW);

    const postfix = props.answer[4].type === 0 ? 'lbs' : 'kg';
    const curDate = moment();

    const coef = answer5 === 0 ? 1 : -1;
    const loose = Math.abs(resultWeight - baseWeight);

    const textLoose = resultWeight > baseWeight ? "gain" : "loose";

    const goToCheckout = () => {
        window.location.href = 'https://checkout.dogfasting.com/';
    }

    const lbs = [
        resultWeight - (3 * coef),
        resultWeight - (2 * coef),
        resultWeight - (1 * coef),
        resultWeight,
        resultWeight + (1 * coef),
        resultWeight + (2 * coef),
        resultWeight + (3 * coef)
    ]

    return (
        <div className={"slide-1"}>
            <Slide1BgOverlay/>
            <div className={"container"}>
                <SummaryHeader/>
                <div className={"row"}>
                    <div className={"left-side"}>
                        <h1>
                            Based on your answers your dog has to <span>{textLoose} {loose} {postfix}</span> so weight
                            could
                            be
                            ideal
                        </h1>
                        <p>
                            We’ve evaluated your answers and prepared a summary out of 10.243 dogs similar to yours
                        </p>
                        {loose <= 1 && <p>
                            Achieving and maintaining healthy body condition scores reduced risk of of illness, adds to
                            general well-being and can extend dog’s life up to two years.
                        </p>}
                        <button className={"btn-next"} onClick={goToCheckout}>Get your plan</button>
                    </div>
                    <div className={"right-side"}>
                        <div className={"graph big"}>
                            <div className={"lbs-body"}>
                                <div className={"lbs-title"}>
                                    {lbs.map((l: number, index: number) => {
                                        return (
                                            <div key={"lbs-" + index} className={"lbs-eq-" + index}>
                                                {l} {index === 3 ? <span>{postfix}</span> : ''}
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className={"lbs-sub-title"}>
                                    By {curDate.format("MMMM Y")}
                                </div>
                                <div className={"lbs-graph " + (answer5 === 0 ? "revert" : "")}>
                                    <img src={answer5 === 0 ? LbsGraphRevert : LbsGraph} alt={""}/>
                                    <div className={"start"}>
                                        <div className={"date"}>{""}</div>
                                        <div className={"lbs-weight"}>{baseWeight} <span>{postfix}</span></div>
                                    </div>
                                    <div className={"end"}>
                                        <div className={"date"}>{""}</div>
                                        <div className={"lbs-weight"}>{resultWeight} <span>{postfix}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"graph small"}>
                            <div className={"title"}>Is fasting safe for your dog?</div>
                            <div className={"heart"}>
                                {heart}
                                <span>YES!</span>
                            </div>
                        </div>
                        <div className={"graph small"}>
                            <div className={"title calendar"}>
                                {days.map((d: string, index: number) => {
                                    return (
                                        <div className={"day"} key={"fasting-" + index}>
                                            <div className={"day-title"}>
                                                {d}
                                            </div>
                                            <div className={"day-number"}>
                                                {index + 1}
                                            </div>
                                            <span className={"dot"}/>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={"weight"}>
                                <div>{loose} <span>{postfix}</span></div>
                                <p>After first week</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const heart = <svg width="68" height="62" viewBox="0 0 68 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M34.0032 5.0966C41.8332 -1.9334 53.9332 -1.70007 61.4765 5.8566C69.0165 13.4166 69.2765 25.4566 62.2632 33.3099L33.9965 61.6166L5.73652 33.3099C-1.27681 25.4566 -1.01348 13.3966 6.52319 5.8566C14.0732 -1.69007 26.1499 -1.9434 34.0032 5.0966Z"
        fill="#E8532C"/>
</svg>

import React from "react";
import {StepProps} from "../QuizRoot";
import NextBtn from "../body/NextBtn";
import {Step1Questions} from "./Step1";
import {step3Index, Step3Questions} from "./Step3";

export const step4Index = 10

export default function Step4(props: StepProps) {

    const answer3 = props.answer[step3Index];
    const text = Step3Questions[answer3].response;

    const onNext = () => {
        props.addAnswer(step4Index, "")
        props.nextStep();
    }

    return (
        <div className={"form-content lg"}>

            <div className={"user-review mb-6"}>
                <p className={"mb-1"} style={{fontWeight: 500}}>
                    Great personal prayer assistant.
                </p>

                <div className="rating-a">
                    <span className="rating">
                        <span className="star"><span className="fill" style={{width: '100%'}}></span></span><span
                        className="star"><span className="fill" style={{width: '100%'}}></span></span><span
                        className="star"><span className="fill" style={{width: '100%'}}></span></span><span
                        className="star"><span className="fill" style={{width: '100%'}}></span></span><span
                        className="star"><span className="fill" style={{width: '100%'}}></span></span>
                    </span>
                </div>

                <p style={{lineHeight: '140%'}} className={"mt-1"}>
                    For the past few months {text}. The Bible Verses, Stories, and Daily Prayers have helped me focus on what is important and feel better.
                </p>
                <p className={"mt-1"}>
                    <i>David L.</i>
                </p>
            </div>
            <NextBtn onClick={onNext}/>
        </div>
    )
}

import React from "react";
import {StepProps} from "../QuizRoot";
import NextBtn from "../body/NextBtn";
import {step1Index, Step1Questions} from "./Step1";
export const step4Index = 4

export default function Step2(props: StepProps) {
    const answer1 = props.answer[step1Index];
    const text = Step1Questions[answer1].response;

    const onNext = () => {
        props.addAnswer(step4Index, "");
        props.nextStep();
    }


    return (
        <div className={"form-content lg"}>

            <div className={"text-center mb-4 p-4 "}>
                {text}
            </div>

            <p className={"text-center mb-4"}>
                <img src={"https://blessify.com/wp-content/uploads/2021/07/Group-219.png"}/>
            </p>

            <p className={"text-center"}>Click continue to personalize your experience</p>

            <NextBtn onClick={onNext}/>

        </div>
    )
}

import React, {Fragment, useState} from "react";
import {StepProps} from "../QuizRoot";
import FiveStarImg from "../../../assets/images/fivestar.png"

export const stepIntroIndex = 1

export default function Intro(props: StepProps) {
    const onNext = () => {
        props.addAnswer(stepIntroIndex, "")
        props.nextStep();
    }

    return (
        <div>
            <div style={{position: 'absolute', left: 0, right: 0, top: 50, zIndex: 10}} className={"hide-mobile"}>
                <img src="https://app.blessify.com/images/intro/IntroBg.png" style={{width: '100%'}}/>
                </div>
            <div className={"form-content lg absolute-mobile"}>

                <div className={"text-center"}><img src={FiveStarImg} width={120}/></div>
                <div className={"text-center mb-4"} style={{fontSize: 14, color: "#666"}}>4.8 stars on 1000+ reviews
                </div>

                <p className={"text-center mb-2"} style={{lineHeight: '120%', fontSize: 14}}>
                    Are you feeling stressed or anxious?<br/>
                    Are you seeking self improvement or inner peace?<br/>
                    Do you want to strengthen your connection to God?<br/>
                    <br/>
                </p>
                <p className={"text-center mb-4"} style={{fontWeight: 500}}>
                    If you answered <span style={{color: '#26C6DA'}}>YES</span> to one of these questions,<br/>start
                    your healing journey with us now.
                </p>
                <div style={{textAlign: 'center'}} className={"mb-4"}>
                    <button onClick={onNext} className={"btn-next"}
                            style={{margin: '0 auto', opacity: 1, maxWidth: 350}}>Start journey
                    </button>
                </div>
                <p className={"text-center mb-2"} style={{fontSize: 14, color: "#666"}}>
                    You will be taken to the quiz which will help us to create<br/> personalized Blessify Experience for
                    you<br/>
                </p>
                <p className={"text-center"}>
                    <img src={"https://app.blessify.com/images/intro/IntroWinners.png"} style={{width: 189}}/>
                </p>
            </div>
        </div>
)
}

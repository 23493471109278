import React, {Fragment} from "react";
import {stepArrow, StepProps} from "../QuizRoot";

export const Step5Questions = [
    {title: 'Get over difficulties in my life', type: 'heal', response: ''},
    {title: 'Find the encouragement to deal with challenges', type: 'heal', response: ''},
    {title: 'Receive forgiveness for my sins', type: 'calm', response: ''},
    {title: 'Help me to calm down and find peace', type: 'calm', response: ''},

    {title: 'Inspire me not to give up', type: 'inspire', response: 'Grow your inner strength to continue'},
    {title: 'Help me to reconnect with God', type: 'inspire', response: ''},
    {title: 'Become better at prayer', type: 'neutral', response: ''},
];

export const step5Index = 5

export default function Step5(props: StepProps) {
    const onClick = (index: number) => {
        props.addAnswer(step5Index, index);
        props.nextStep();
    }

    return (
        <div className={"form-content lg"}>
            {Step5Questions.map((variant: any, index: number) => {
                return (
                    <button key={"step-3-" + index}
                            className={"btn-quiz " + (props.answer[step5Index] === index ? "active" : "")}
                            onClick={() => onClick(index)}>
                        <span>{variant.title}</span> {stepArrow}
                    </button>
                )
            })}
        </div>
    )
}

import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import QuizRoot from "./components/quiz/QuizRoot";
import QuizGender from "./components/quiz/QuizGender";

function App() {
    return (
        <Router>
            <Switch>
                <Route path={"/"}><QuizRoot/></Route>
            </Switch>
        </Router>
    );
}

export default App;

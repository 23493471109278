import React from "react";
import {StepProps} from "../QuizRoot";
import NextBtn from "../body/NextBtn";
import {step12Index, Step12Variants} from "./Step12";

export const step13Index = 14


export default function Step13(props: StepProps) {
    const answer12 = props.answer[step12Index];
    const text = Step12Variants[answer12];

    const onNext = () => {
        props.addAnswer(step13Index, "");
        props.nextStep();
    }


    return (
        <div className={"form-content lg"}>

            <div className={"text-center mb-4 p-4 "}>
                79% of Blessify users of the age {text} felt the difference in the state of mind in less than a month.
            </div>

            <p className={"text-center mb-4"}>
                <img src={"https://blessify.com/wp-content/uploads/2021/07/Group-219.png"}/>
            </p>


            <NextBtn onClick={onNext}/>

        </div>
    )
}

import React from "react";
import {StepProps} from "../QuizRoot";
import NextBtn from "../body/NextBtn";
export const step6Index = 6

export default function Step6(props: StepProps) {
    const onNext = () => {
        props.addAnswer(step6Index, "")
        props.nextStep();
    }

    return (
        <div className={"form-content"}>
            <p className={"text-center mb-4"} style={{lineHeight: '160%'}}>
                Recognizing the area where you need help is important, so we’ll keep checking in with you.
            </p>
            <NextBtn onClick={onNext}/>
        </div>
    )
}

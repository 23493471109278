import React from "react";

interface Props {
    onClick: () => void,
    step: number
}

export default function HeaderBack(props: Props) {
    return (
        <div className={"back"}>
            {props.step > 1 &&
            <button onClick={props.onClick}>
                {arrow} Back
            </button>
            }
        </div>
    )
}

const arrow = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z" fill="#26C6DA"/>
</svg>

import React from 'react'
import Slide1 from "./summary/Slide1";
import Slide2 from "./summary/Slide2";
import Slide3 from "./summary/Slide3";

export interface SummaryProps {
    answer: any,
}

export default function QuizSummary(props: SummaryProps) {
    // console.log('props', props.answer);
    return (
        <div className={"quiz-summary"}>
            <Slide1 {...props}/>
            <Slide2 {...props}/>
            <Slide3 {...props}/>
        </div>

    )
}

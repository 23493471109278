import React from "react";

export default function SummaryHeader() {
    return (
        <div className={"summary-header"}>
            <div className={"logo"}>
                {logo}
            </div>
        </div>
    )
}

const logo = <svg width="163" height="50" viewBox="0 0 163 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M62.264 32.51C62.1907 32.51 62.1247 32.488 62.066 32.444C62.022 32.3853 62 32.3193 62 32.246V17.374C62 17.3007 62.022 17.242 62.066 17.198C62.1247 17.1393 62.1907 17.11 62.264 17.11H67.764C68.864 17.11 69.8247 17.308 70.646 17.704C71.482 18.0853 72.1273 18.628 72.582 19.332C73.0367 20.036 73.264 20.85 73.264 21.774V27.846C73.264 28.77 73.0367 29.584 72.582 30.288C72.1273 30.992 71.482 31.542 70.646 31.938C69.8247 32.3193 68.864 32.51 67.764 32.51H62.264ZM65.102 29.738C65.102 29.8113 65.1387 29.848 65.212 29.848H67.874C68.5487 29.848 69.0913 29.6207 69.502 29.166C69.9273 28.7113 70.1473 28.1027 70.162 27.34V22.28C70.162 21.5173 69.9493 20.9087 69.524 20.454C69.1133 19.9993 68.556 19.772 67.852 19.772H65.212C65.1387 19.772 65.102 19.8087 65.102 19.882V29.738Z" fill="#0D160B"/>
    <path d="M80.3956 32.686C79.1636 32.686 78.1076 32.3633 77.2276 31.718C76.3476 31.0727 75.7463 30.1927 75.4236 29.078C75.2183 28.3887 75.1156 27.6407 75.1156 26.834C75.1156 25.9687 75.2183 25.1913 75.4236 24.502C75.7609 23.4167 76.3696 22.566 77.2496 21.95C78.1296 21.334 79.1856 21.026 80.4176 21.026C81.6203 21.026 82.6469 21.334 83.4976 21.95C84.3483 22.5513 84.9496 23.3947 85.3016 24.48C85.5363 25.2133 85.6536 25.9833 85.6536 26.79C85.6536 27.582 85.5509 28.3227 85.3456 29.012C85.0229 30.156 84.4216 31.058 83.5416 31.718C82.6763 32.3633 81.6276 32.686 80.3956 32.686ZM80.3956 30.024C80.8796 30.024 81.2903 29.8773 81.6276 29.584C81.9649 29.2907 82.2069 28.8873 82.3536 28.374C82.4709 27.9047 82.5296 27.3913 82.5296 26.834C82.5296 26.218 82.4709 25.6973 82.3536 25.272C82.1923 24.7733 81.9429 24.3847 81.6056 24.106C81.2683 23.8273 80.8576 23.688 80.3736 23.688C79.8749 23.688 79.4569 23.8273 79.1196 24.106C78.7969 24.3847 78.5623 24.7733 78.4156 25.272C78.2983 25.624 78.2396 26.1447 78.2396 26.834C78.2396 27.494 78.2909 28.0073 78.3936 28.374C78.5403 28.8873 78.7823 29.2907 79.1196 29.584C79.4716 29.8773 79.8969 30.024 80.3956 30.024Z" fill="#0D160B"/>
    <path d="M94.5557 21.466C94.5557 21.3927 94.5777 21.334 94.6217 21.29C94.6804 21.2313 94.7464 21.202 94.8197 21.202H97.3937C97.467 21.202 97.5257 21.2313 97.5697 21.29C97.6284 21.334 97.6577 21.3927 97.6577 21.466V31.498C97.6577 33.4193 97.1004 34.7907 95.9857 35.612C94.8857 36.4333 93.463 36.844 91.7177 36.844C91.2044 36.844 90.6324 36.8073 90.0017 36.734C89.855 36.7193 89.7817 36.624 89.7817 36.448L89.8697 34.182C89.8697 33.9913 89.9724 33.9107 90.1777 33.94C90.7057 34.028 91.1897 34.072 91.6297 34.072C92.5684 34.072 93.287 33.8667 93.7857 33.456C94.299 33.0453 94.5557 32.3853 94.5557 31.476C94.5557 31.432 94.541 31.41 94.5117 31.41C94.4824 31.3953 94.453 31.4027 94.4237 31.432C93.8664 32.0333 93.0524 32.334 91.9817 32.334C91.0284 32.334 90.1484 32.1067 89.3417 31.652C88.5497 31.1827 87.985 30.4347 87.6477 29.408C87.4277 28.7333 87.3177 27.8607 87.3177 26.79C87.3177 25.646 87.4497 24.7147 87.7137 23.996C88.0217 23.1013 88.5424 22.3827 89.2757 21.84C90.009 21.2973 90.8597 21.026 91.8277 21.026C92.957 21.026 93.8224 21.3633 94.4237 22.038C94.453 22.0673 94.4824 22.082 94.5117 22.082C94.541 22.0673 94.5557 22.038 94.5557 21.994V21.466ZM94.3797 28.572C94.497 28.132 94.5557 27.5307 94.5557 26.768C94.5557 26.3133 94.541 25.9613 94.5117 25.712C94.4824 25.4627 94.431 25.228 94.3577 25.008C94.2257 24.612 94.0057 24.2967 93.6977 24.062C93.3897 23.8127 93.0084 23.688 92.5537 23.688C92.1137 23.688 91.7397 23.8127 91.4317 24.062C91.1237 24.2967 90.8964 24.612 90.7497 25.008C90.5444 25.448 90.4417 26.042 90.4417 26.79C90.4417 27.6113 90.5297 28.198 90.7057 28.55C90.8377 28.946 91.065 29.2687 91.3877 29.518C91.725 29.7527 92.121 29.87 92.5757 29.87C93.045 29.87 93.4337 29.7527 93.7417 29.518C94.0497 29.2687 94.2624 28.9533 94.3797 28.572Z" fill="#0D160B"/>
    <path d="M104.69 18.144C103.825 18.144 103.231 18.3493 102.908 18.76C102.6 19.1707 102.446 19.838 102.446 20.762V21.334C102.446 21.3927 102.476 21.422 102.534 21.422H105.108C105.255 21.422 105.328 21.4953 105.328 21.642V22.28C105.328 22.4267 105.255 22.5 105.108 22.5H102.534C102.476 22.5 102.446 22.5293 102.446 22.588V32.29C102.446 32.4367 102.373 32.51 102.226 32.51H101.5C101.354 32.51 101.28 32.4367 101.28 32.29V22.588C101.28 22.5293 101.251 22.5 101.192 22.5H99.7403C99.5936 22.5 99.5203 22.4267 99.5203 22.28V21.642C99.5203 21.4953 99.5936 21.422 99.7403 21.422H101.192C101.251 21.422 101.28 21.3927 101.28 21.334V20.718C101.28 19.8527 101.376 19.1633 101.566 18.65C101.772 18.1367 102.109 17.7553 102.578 17.506C103.062 17.242 103.737 17.11 104.602 17.11H105.174C105.321 17.11 105.394 17.1833 105.394 17.33V17.924C105.394 18.0707 105.321 18.144 105.174 18.144H104.69Z" fill="#0D160B"/>
    <path d="M111.557 21.246C112.847 21.246 113.83 21.5833 114.505 22.258C115.179 22.918 115.517 23.8127 115.517 24.942V32.29C115.517 32.4367 115.443 32.51 115.297 32.51H114.571C114.424 32.51 114.351 32.4367 114.351 32.29V31.256C114.351 31.2267 114.336 31.2047 114.307 31.19C114.292 31.1753 114.27 31.1827 114.241 31.212C113.859 31.696 113.361 32.0627 112.745 32.312C112.143 32.5613 111.469 32.686 110.721 32.686C109.709 32.686 108.851 32.4293 108.147 31.916C107.443 31.4027 107.091 30.618 107.091 29.562C107.091 28.4767 107.494 27.6187 108.301 26.988C109.107 26.3573 110.215 26.042 111.623 26.042H114.263C114.321 26.042 114.351 26.0127 114.351 25.954V24.964C114.351 24.1427 114.116 23.49 113.647 23.006C113.192 22.522 112.488 22.28 111.535 22.28C110.787 22.28 110.171 22.434 109.687 22.742C109.203 23.05 108.902 23.4607 108.785 23.974C108.741 24.1207 108.653 24.1867 108.521 24.172L107.751 24.062C107.589 24.0327 107.523 23.974 107.553 23.886C107.685 23.094 108.11 22.456 108.829 21.972C109.562 21.488 110.471 21.246 111.557 21.246ZM110.919 31.674C111.843 31.674 112.642 31.4467 113.317 30.992C114.006 30.5373 114.351 29.9067 114.351 29.1V27.098C114.351 27.0393 114.321 27.01 114.263 27.01H111.733C110.691 27.01 109.848 27.23 109.203 27.67C108.572 28.11 108.257 28.7187 108.257 29.496C108.257 30.2147 108.506 30.7573 109.005 31.124C109.503 31.4907 110.141 31.674 110.919 31.674Z" fill="#0D160B"/>
    <path d="M122.465 32.62C121.673 32.62 120.969 32.4953 120.353 32.246C119.752 31.982 119.283 31.63 118.945 31.19C118.608 30.75 118.439 30.266 118.439 29.738V29.452C118.439 29.3053 118.513 29.232 118.659 29.232H119.341C119.488 29.232 119.561 29.3053 119.561 29.452V29.694C119.561 30.2367 119.833 30.706 120.375 31.102C120.918 31.4833 121.607 31.674 122.443 31.674C123.265 31.674 123.932 31.476 124.445 31.08C124.959 30.684 125.215 30.1927 125.215 29.606C125.215 29.1953 125.083 28.858 124.819 28.594C124.555 28.33 124.24 28.132 123.873 28C123.521 27.8533 122.986 27.67 122.267 27.45C121.446 27.2007 120.786 26.966 120.287 26.746C119.789 26.526 119.363 26.218 119.011 25.822C118.674 25.4113 118.505 24.898 118.505 24.282C118.505 23.3873 118.857 22.676 119.561 22.148C120.28 21.6053 121.219 21.334 122.377 21.334C123.155 21.334 123.837 21.466 124.423 21.73C125.025 21.9793 125.487 22.324 125.809 22.764C126.147 23.204 126.315 23.7027 126.315 24.26V24.326C126.315 24.4727 126.242 24.546 126.095 24.546H125.413C125.267 24.546 125.193 24.4727 125.193 24.326V24.26C125.193 23.7027 124.929 23.2407 124.401 22.874C123.888 22.4927 123.206 22.302 122.355 22.302C121.534 22.302 120.874 22.478 120.375 22.83C119.877 23.1673 119.627 23.6293 119.627 24.216C119.627 24.7733 119.855 25.1913 120.309 25.47C120.779 25.7487 121.505 26.0347 122.487 26.328C123.338 26.5773 124.013 26.812 124.511 27.032C125.025 27.252 125.465 27.5673 125.831 27.978C126.198 28.374 126.381 28.8947 126.381 29.54C126.381 30.464 126.022 31.212 125.303 31.784C124.599 32.3413 123.653 32.62 122.465 32.62Z" fill="#0D160B"/>
    <path d="M134.116 22.192C134.116 22.3387 134.042 22.412 133.896 22.412H131.322C131.263 22.412 131.234 22.4413 131.234 22.5V29.452C131.234 30.2 131.402 30.728 131.74 31.036C132.077 31.3293 132.62 31.476 133.368 31.476H133.874C134.02 31.476 134.094 31.5493 134.094 31.696V32.29C134.094 32.4367 134.02 32.51 133.874 32.51L133.214 32.532C132.187 32.532 131.41 32.334 130.882 31.938C130.368 31.542 130.104 30.816 130.09 29.76V22.5C130.09 22.4413 130.06 22.412 130.002 22.412H128.682C128.535 22.412 128.462 22.3387 128.462 22.192V21.642C128.462 21.4953 128.535 21.422 128.682 21.422H130.002C130.06 21.422 130.09 21.3927 130.09 21.334V18.826C130.09 18.6793 130.163 18.606 130.31 18.606H131.014C131.16 18.606 131.234 18.6793 131.234 18.826V21.334C131.234 21.3927 131.263 21.422 131.322 21.422H133.896C134.042 21.422 134.116 21.4953 134.116 21.642V22.192Z" fill="#0D160B"/>
    <path d="M138.304 19.2C137.981 19.2 137.717 19.0973 137.512 18.892C137.307 18.6867 137.204 18.4227 137.204 18.1C137.204 17.7773 137.307 17.5133 137.512 17.308C137.717 17.1027 137.981 17 138.304 17C138.627 17 138.891 17.1027 139.096 17.308C139.301 17.5133 139.404 17.7773 139.404 18.1C139.404 18.4227 139.301 18.6867 139.096 18.892C138.891 19.0973 138.627 19.2 138.304 19.2ZM137.886 32.488C137.739 32.488 137.666 32.4147 137.666 32.268V21.62C137.666 21.4733 137.739 21.4 137.886 21.4H138.612C138.759 21.4 138.832 21.4733 138.832 21.62V32.268C138.832 32.4147 138.759 32.488 138.612 32.488H137.886Z" fill="#0D160B"/>
    <path d="M147.387 21.268C148.516 21.268 149.426 21.6127 150.115 22.302C150.819 22.9913 151.171 23.9007 151.171 25.03V32.29C151.171 32.4367 151.098 32.51 150.951 32.51H150.225C150.078 32.51 150.005 32.4367 150.005 32.29V25.25C150.005 24.3847 149.741 23.6807 149.213 23.138C148.685 22.5807 147.996 22.302 147.145 22.302C146.265 22.302 145.554 22.5733 145.011 23.116C144.468 23.644 144.197 24.3407 144.197 25.206V32.29C144.197 32.4367 144.124 32.51 143.977 32.51H143.251C143.104 32.51 143.031 32.4367 143.031 32.29V21.642C143.031 21.4953 143.104 21.422 143.251 21.422H143.977C144.124 21.422 144.197 21.4953 144.197 21.642V22.72C144.197 22.764 144.204 22.7933 144.219 22.808C144.248 22.808 144.27 22.7933 144.285 22.764C144.608 22.28 145.033 21.9133 145.561 21.664C146.089 21.4 146.698 21.268 147.387 21.268Z" fill="#0D160B"/>
    <path d="M161.711 21.642C161.711 21.4953 161.785 21.422 161.931 21.422H162.657C162.804 21.422 162.877 21.4953 162.877 21.642V32.268C162.877 33.9253 162.379 35.128 161.381 35.876C160.384 36.624 158.976 36.9613 157.157 36.888C157.011 36.8733 156.937 36.7927 156.937 36.646L156.959 36.008C156.959 35.8613 157.033 35.788 157.179 35.788C158.749 35.8467 159.893 35.5827 160.611 34.996C161.345 34.424 161.711 33.5073 161.711 32.246V31.146C161.711 31.102 161.697 31.08 161.667 31.08C161.653 31.0653 161.631 31.0727 161.601 31.102C160.941 32.114 159.937 32.62 158.587 32.62C157.634 32.62 156.798 32.3633 156.079 31.85C155.361 31.322 154.884 30.6033 154.649 29.694C154.488 29.0927 154.407 28.1907 154.407 26.988C154.407 25.6973 154.495 24.7513 154.671 24.15C154.906 23.2847 155.368 22.588 156.057 22.06C156.747 21.5173 157.575 21.246 158.543 21.246C159.893 21.246 160.912 21.7447 161.601 22.742C161.616 22.7713 161.638 22.7787 161.667 22.764C161.697 22.7493 161.711 22.7273 161.711 22.698V21.642ZM161.601 29.276C161.645 29.056 161.675 28.7847 161.689 28.462C161.704 28.1393 161.711 27.6407 161.711 26.966C161.711 26.2913 161.704 25.7927 161.689 25.47C161.675 25.1473 161.645 24.8613 161.601 24.612C161.484 23.9373 161.169 23.38 160.655 22.94C160.157 22.5 159.526 22.28 158.763 22.28C158.001 22.28 157.355 22.5 156.827 22.94C156.314 23.38 155.962 23.9373 155.771 24.612C155.639 25.052 155.573 25.8293 155.573 26.944C155.573 28.0293 155.639 28.8067 155.771 29.276C155.933 29.9507 156.277 30.508 156.805 30.948C157.348 31.388 158.001 31.608 158.763 31.608C159.555 31.608 160.193 31.388 160.677 30.948C161.176 30.508 161.484 29.9507 161.601 29.276Z" fill="#0D160B"/>
    <path d="M50 24.9713C50 38.7486 38.806 49.9426 25.0287 49.9426C24.5695 49.9426 24.1102 49.9426 23.651 49.8852C23.5936 49.8852 23.5936 49.8852 23.5362 49.8852C22.9621 49.8278 22.3881 49.8278 21.8714 49.713C12.5144 48.5649 4.70723 42.1355 1.54994 33.5821C1.37773 33.1228 1.20551 32.6636 1.0907 32.2044C1.0333 31.9173 0.918485 31.6877 0.861079 31.4007C0.287026 29.3915 0 27.2101 0 24.9713C0 11.194 11.194 0 24.9713 0C38.806 0 50 11.194 50 24.9713Z" fill="#26C6DA"/>
    <path d="M38.5191 39.208C37.1413 39.6673 24.627 36.6248 24.2826 37.8303C24.0529 38.5191 24.1103 44.4319 23.6511 49.9428C23.5937 49.9428 23.5937 49.9428 23.5363 49.9428C22.9622 49.8854 22.3882 49.8854 21.8715 49.7706C12.5145 48.6225 4.70735 42.1931 1.55006 33.6397C1.37785 33.1805 1.20563 32.7212 1.09082 32.262C4.01849 30.138 9.81642 25.4881 11.4812 20.4939C11.5386 20.2069 10.046 18.2551 9.75902 16.5903C8.95534 11.7109 14.1218 7.46289 14.1218 7.46289C14.1218 7.46289 15.3847 11.1942 16.8773 13.6053C17.5087 14.696 18.255 15.4422 18.8865 15.6144C20.0346 15.8441 22.6178 15.7867 23.9955 16.1885C24.1677 16.2459 24.34 16.3033 24.5122 16.4181C27.2102 17.8532 29.3342 20.2069 30.0805 22.5605C31.1138 25.9474 41.0449 29.1047 42.2504 30.3676C43.3985 31.5731 39.8968 38.7488 38.5191 39.208Z" fill="white"/>
    <path d="M21.4123 6.7168C21.4123 6.7168 16.5328 10.1611 16.8773 14.9832C17.2217 19.8052 19.6901 18.3701 19.8624 18.3701C20.0346 18.3701 24.2252 17.1646 24.2252 17.1646L21.4123 6.7168Z" fill="white"/>
</svg>

import React from "react";

interface Props {
    current: number,
    all: number,
}

export default function HeaderProgress(props: Props) {
    const p = Math.ceil(((props.current - 1) / props.all) * 100);
    return (
        <div className={"progress"} style={{width: p + '%'}}/>
    )
}

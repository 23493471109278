import React, {Fragment} from "react";
import {stepArrow, StepProps} from "../QuizRoot";
import NextBtn from "../body/NextBtn";
import {step10Index} from "./Step10";

export const step11Index = 12

export default function Step11(props: StepProps) {
    const answer10 = props.answer[step10Index];

    const onNext = () => {
        props.addAnswer(step11Index, "")
        props.nextStep();
    }

    return (
        <div className={"form-content lg"}>
            <p className={"text-center mb-2"} style={{lineHeight: '160%', fontSize: 16}}>
                {answer10 === 0 && <Fragment>
                    Congratulations on taking the first step towards becoming a happier and healthier you!<br/>
                    We'll make it easy for you to learn the basics.<br/>
                </Fragment>}
                {answer10 === 1 && <Fragment>
                Great! We'll help you deepen your knowledge and build a lasting prayer habit.<br/>
                </Fragment>}
                {answer10 === 2 && <Fragment>
                    Great! We'll help you to continue deepening and evolving your practice.<br/>
                </Fragment>
                }
            </p>

            <p className={"text-center mb-2"}>
                <img src={"https://blessify.com/wp-content/uploads/2021/07/Group-299-3.png"}/>
            </p>

            <NextBtn onClick={onNext}/>
        </div>
    )
}

const variants = [
    "More than 12 hours",
    "8-12 hours",
    "8-4 hours",
    "Less than 4 hours",
]

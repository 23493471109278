import React from "react";

import BottomLeftImg from "../../../assets/images/bg/bgBottomLeft.png"
import TopRightImg from "../../../assets/images/bg/bgTopRight.png"

export default function BgOverlay() {
    return (
        <div className={"bg-overlay"}>
            <img src={BottomLeftImg} className={"bottom-left"} alt={""}/>
            <img src={TopRightImg} className={"top-right"} alt={""}/>
        </div>
    )
}


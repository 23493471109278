import React from "react";
import {stepArrow, StepProps} from "../QuizRoot";

export const step10Index = 11

export default function Step10(props: StepProps) {
    const onClick = (index: number) => {
        props.addAnswer(step10Index, index);
        props.nextStep();
    }

    return (
        <div className={"form-content"}>
            {variants.map((variant: string, index: number) => {
                return (
                    <button key={"step-10-" + index}
                            className={"btn-quiz " + (props.answer[step10Index] === index ? "active" : "")}
                            onClick={() => onClick(index)}>
                        <span>{variant}</span> {stepArrow}
                    </button>
                )
            })}
        </div>
    )
}

const variants = [
    "Beginner",
    "I know a thing or two",
    "Advanced",
]

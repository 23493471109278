import React, {Fragment, useEffect, useState} from "react";
import Header from "./header/Header";
import QuizContainer from "./body/QuizContainer";
import Step1 from "./questions/Step1";
import Step2 from "./questions/Step2";
import Step3 from "./questions/Step3";
import Step4 from "./questions/Step4";
import Step5 from "./questions/Step5";
import Step6 from "./questions/Step6";
import Step7 from "./questions/Step7";
import Step8 from "./questions/Step8";
import Step9 from "./questions/Step9";
import Step10 from "./questions/Step10";
import Step11 from "./questions/Step11";
import Step12 from "./questions/Step12";
import QuizProgress from "./body/QuizProgress";
import QuizSummary from "./QuizSummary";
import {useParams} from "react-router-dom";
import Step13 from "./questions/Step13";
import Step14 from "./questions/Step14";
import Step15 from "./questions/Step15";
import {v4 as uuidv4} from 'uuid';
import Intro from "./questions/Intro";

export const stepsCount = 16;

export interface StepProps {
    answer: any,
    addAnswer: (step: number, val: any) => void,
    nextStep: () => void,
}

export default function QuizRoot() {
    const params: any = useParams();
    const [step, setStep] = useState(1);
    const nextStep = () => setStep(step + 1);

    const [secretId] = useState(uuidv4());
    const [answer, setAnswer] = useState<any>({gender: params.male});


    const addAnswer = (step: number, val: any) => {
        const _answer = {...answer};
        _answer[step] = val;
        setAnswer(_answer);
    }


    const saveData = (data: any) => {
        fetch('https://quiz.blessify.com/save/praytify/' + secretId,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }
        ).then(() => {

        }).catch(e => {});
    }

    useEffect(() => {
        saveData(answer);
    }, [answer]);


    const getContent = () => {
        switch (step) {
            case 1:
                return <Intro answer={answer} addAnswer={addAnswer} nextStep={nextStep}/>
            case 2:
                return <Step3 answer={answer} addAnswer={addAnswer} nextStep={nextStep}/>
            case 3:
                return <Step1 answer={answer} addAnswer={addAnswer} nextStep={nextStep}/>
            case 4:
                return <Step2 answer={answer} addAnswer={addAnswer} nextStep={nextStep}/>
            case 5:
                return <Step5 answer={answer} addAnswer={addAnswer} nextStep={nextStep}/>
            case 6:
                return <Step6 answer={answer} addAnswer={addAnswer} nextStep={nextStep}/>
            case 7:
                return <Step7 answer={answer} addAnswer={addAnswer} nextStep={nextStep}/>
            case 8:
                return <Step8 answer={answer} addAnswer={addAnswer} nextStep={nextStep}/>
            case 9:
                return <Step9 answer={answer} addAnswer={addAnswer} nextStep={nextStep}/>
            case 10:
                return <Step4 answer={answer} addAnswer={addAnswer} nextStep={nextStep}/>
            case 11:
                return <Step10 answer={answer} addAnswer={addAnswer} nextStep={nextStep}/>
            case 12:
                return <Step11 answer={answer} addAnswer={addAnswer} nextStep={nextStep}/>
            case 13:
                return <Step12 answer={answer} addAnswer={addAnswer} nextStep={nextStep}/>
            case 14:
                return <Step13 answer={answer} addAnswer={addAnswer} nextStep={nextStep}/>
            case 15:
                return <Step14 answer={answer} addAnswer={addAnswer} nextStep={nextStep}/>
            case 16:
                return <Step15 answer={answer} addAnswer={addAnswer} nextStep={nextStep}/>
            case 17:
                return <QuizProgress nextStep={nextStep} answer={answer}/>

            default:
                return <Fragment/>

        }
    }

    return (
        <Fragment>
            {step < 18 ?
                <Fragment>
                    <Header step={step} setStep={setStep}/>
                    <QuizContainer step={step} setStep={setStep}>
                        {getContent()}
                    </QuizContainer>
                </Fragment>
                : <QuizSummary answer={answer}/>
            }
        </Fragment>
    )
}

export const stepArrow = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" fill="#26C6DA"/>
</svg>

import React from "react";
import {StepProps} from "../QuizRoot";
import NextBtn from "../body/NextBtn";

export const step15Index = 16

export default function Step15(props: StepProps) {

    const reviews = [
        {
            'name': 'Barbara Dalzell',
            'review': 'A lot of different content to match your current state. For the past several months I’ve been feeling sad and lost. The Bible Verses, Stories and Daily Prayers helped me to focus on what is important and rediscover my purpose.',
            'image': 'https://blessify.com/wp-content/uploads/2021/07/Barbara.png'
        },
        {
            'name': 'Darrel M. Lee',
            'review': 'Great Personal Pray Assistant. I am feeling happy and strong. Blessify app helped me to focus on what is important and change my perspective on a daily challenges, struggles.',
            'image': 'https://blessify.com/wp-content/uploads/2021/07/Darrel-1.png'
        },
        {
            'name': 'Sylvia Mosley',
            'review': 'Great way to return to inner balance. With Blessify app I found and effective way to calm down, especially before the sleep and have a proper rest at night. I do feel much less stressed than I used to.',
            'image': 'https://blessify.com/wp-content/uploads/2021/07/Sylvia.png'
        }
    ]

    const onNext = () => {
        props.addAnswer(step15Index, "")
        props.nextStep();
    }

    return (
        <div className={"form-content lg"}>

            {reviews.map((review: any, index: number) => {
                return (
                    <div className={"user-review mb-6"} key={"review-" + index}>
                        <div style={{display: 'flex'}}>
                            <img src={review.image} height={80} width={80} style={{marginRight: 10}}/>
                            <div>
                                <p className={"mb-1 mt-1"} style={{fontWeight: 500}}>
                                    Great personal prayer assistant.
                                </p>

                                <div className="rating-a">
                            <span className="rating">
                                <span className="star"><span className="fill"
                                                             style={{width: '100%'}}></span></span><span
                                className="star"><span className="fill" style={{width: '100%'}}></span></span><span
                                className="star"><span className="fill" style={{width: '100%'}}></span></span><span
                                className="star"><span className="fill" style={{width: '100%'}}></span></span><span
                                className="star"><span className="fill" style={{width: '100%'}}></span></span>
                            </span>
                                </div>
                                <p className={"mt-1"}>
                                    <i>{review.name}</i>
                                </p>
                            </div>
                        </div>

                        <p style={{lineHeight: '140%'}} className={"mt-1"}>
                            {review.review}
                        </p>

                    </div>
                )
            })}

            <NextBtn onClick={onNext}/>
        </div>
    )
}
